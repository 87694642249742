<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { context } from 'o365-modules';
    import ContextSelector from 'o365.vue.components.ContextSelector.vue';

    const emits = defineEmits(['contextChanged']);

    const isLoaded = ref(false);

    const onContextChanged = (contextID) => {
        emits('contextChanged', contextID);
    }

    onMounted(async () => {
        await context.updateContextFromDB();
        isLoaded.value = true;
    });
</script>

<template>
    <ContextSelector v-if="isLoaded" @contextChanged="onContextChanged"></ContextSelector>

    <div v-else class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>